import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import BackupIcon from "@mui/icons-material/Backup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Popper,
  Fade,
  Paper,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { LuImagePlus } from "react-icons/lu";
import ClearIcon from "@mui/icons-material/Clear";
import { RiQuestionLine } from "react-icons/ri";
import { REACT_APP_NODE_BACKEND_URL, REACT_APP_CURRENT_URL } from "./config";

/*-----------------------------------------convertBase64ToFileStart------------------------------------*/
function base64ToFile(base64String, fileName = 'image.png', contentType = 'image/png') {
  const base64StringWithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, '');
  const addPadding = (str) => {
    while (str.length % 4) {
        str += '=';
    }
    return str;
};
const base64StringWithPadding = addPadding(base64StringWithoutPrefix);
  const byteCharacters = atob(base64StringWithPadding);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });

  // Create a File object
  const file = new File([blob], fileName, { type: contentType });
  return file;
}

/*-----------------------------------------convertBase64ToFileEnd--------------------------------------*/

function Help() {
  const [imageUrl, setImageUrl] = useState(null);
  const [takeShot, setTakeShot] = useState(true);

  const location = useLocation();
  const clientName = new URLSearchParams(location.search).get("client");
  const clientType = new URLSearchParams(location.search).get("clientType");
  const deviceType = new URLSearchParams(location.search).get("deviceType");

  const initialFormData = {
    name: "",
    email: "",
    mobileNumber: "",
    subject: "",
    type: "Issue",
    clientName: clientName ? clientName : "",
    clientType: clientType ? clientType : "",
    priority: "Low",
    attachment: [],
    description: "",
    screenshot: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [anchorEl, setAnchorEl] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isValidate, setIsValidate] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [placement, setPlacement] = useState("");
  const [open, setOpen] = useState(false);
  const helpBtn = useRef(null);
  /* const dragItem = useRef(null);
  const dragProps = useRef(); */
  const validation = (data) => {
    const validationError = {};
    if (!data.name) {
      validationError.name = "Name is required";
    }
    if (!data.email) {
      validationError.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      validationError.email = "Invalid email format";
    }
    if (!data.subject) {
      validationError.subject = "Subject is required";
    }
    if (!data.description) {
      validationError.description = "Description is required";
    }
    return validationError;
  };

  /* const handleClose = () => {
    setAnchorEl(null);
    setFormData(initialFormData);
  }; */

  const handleFileRemove = (index) => {
    const removeData = formData.attachment.filter((item, i) => {
      return index !== i;
    });

    setFormData((previous) => {
      return { ...previous, attachment: removeData };
    });
  };

  const handleChange = (value, key) => {
    let updatedValue = value;
    const imageExtensions = ["jpeg", "png", "jpg","xlsx"];

    if (key === "attachment") {
      let previousAttachment = formData.attachment;
      updatedValue = previousAttachment.concat(...value);

      updatedValue = updatedValue.filter((file) => {
        const extension = file.name.split(".").pop().toLowerCase();
        const isImage = imageExtensions.includes(extension);

        if (!isImage) {
          errors.fileTypeAndSize = "Invalid file type";
        }
        if (!isImage && updatedValue.length > 5) {
          errors.fileTypeAndSize =
            "Invalid file type & File count exceeds the allowed limit of 5";
        } else if (updatedValue.length > 5) {
          errors.fileTypeAndSize =
            "The number of files exceeds the permitted limit of 5";
        }
        return isImage;
      });

      // updatedValue = updatedValue.filter(
      //   (value, index, self) =>
      //     index === self.findIndex((obj) => obj.name === value.name)
      // );
      if (updatedValue.length > 5) updatedValue.splice(5);
    }

    setFormData((prev) => {
      return { ...prev, [key]: updatedValue };
    });
  };

  const handleChangeIframeSize = () => {
    window.parent.postMessage({ iframe: showIframe }, "*");
  };

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    setShowIframe(!showIframe);
    handleChangeIframeSize();
  };
  const getShortString = (el, length, isToolTip = true) => {
    if (el === "" || el === null || el === undefined) {
      return "";
    }
    const totalLength = el.length;
    if (totalLength <= length) {
      return el;
    }
    if (isToolTip)
      return (
        <Tooltip title={el} arrow placement="top">
          <span>{el.substr(0, length) + "..."}</span>
        </Tooltip>
      );
    return el.substr(0, length) + "...";
  };

  const handleSubmit = () => {
    setIsValidate(true);
    if (
      Object.keys(errors).length === 0 &&
      formData.name &&
      formData.email &&
      formData.subject &&
      formData.description
    ) {
      const fileData = []
      const payload = new FormData();
      for (let i = 0; i < formData.attachment.length; i++) {
        fileData.push(formData.attachment[i]);
      }
      if(imageUrl) fileData.push(base64ToFile(imageUrl))
      payload.append("name", formData.name);
      payload.append("email", formData.email);
      payload.append("mobileNumber", formData.mobileNumber);
      payload.append("subject", formData.subject);
      payload.append("clientId", formData.clientName);
      payload.append("clientType", formData.clientType);
      payload.append("type", formData.type);
      payload.append("description", formData.description);
      payload.append("priority", formData.priority);   
      fileData.forEach(file => {
        payload.append("file",file)
      });  
      
      axios.post(`${REACT_APP_NODE_BACKEND_URL}/support/createIssue`, payload)
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
      setFormData(initialFormData);
      setAnchorEl(null);
      setSubmitted(true);
      const timer = setTimeout(() => {
        setOpen(!open);
        setShowIframe(false);
        setSubmitted(false);
        setFormData(initialFormData);
        setTakeShot(true);
        handleChangeIframeSize();
        setIsValidate(false);
        setErrors({});
      }, 1500);
      return () => clearTimeout(timer);
    }
  };
  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  const handleTakeScreenShot = () => {
    window.parent.postMessage("captureScreenShot", "*");
  };

  // const handleClick = (key) => {
  //   setAnchorEl(!anchorEl);
  //   setShowIframe(!showIframe);
  //   // setPlacement(key);
  // };

  //-------------------------mousemoveEvent---------------------------//

  // const initialiseDrag = (e) => {
  //   const { target, clientX, clientY } = e;
  //   const { offsetTop, offsetLeft } = target;
  //   const { left, top } = dragItem.current.getBoundingClientRect();

  //   dragProps.current = {
  //     dragStartLeft: left - offsetLeft,
  //     dragStartTop: top - offsetTop,
  //     dragStartX: clientX,
  //     dragStartY: clientY,
  //   };

  //   window.addEventListener("mousemove", startDragging, false);
  //   window.addEventListener("mouseup", stopDragging, false);
  // };

  // const startDragging = ({ clientX, clientY }) => {
  //   dragItem.current.style.transform = `translate(${
  //     dragProps.current.dragStartLeft + clientX - dragProps.current.dragStartX
  //   }px,${
  //     dragProps.current.dragStartTop + clientY - dragProps.current.dragStartY
  //   }px)`;
  // };

  // const stopDragging = () => {
  //   window.removeEventListener("mousemove", startDragging, false);
  //   window.removeEventListener("mouseup", stopDragging, false);
  // };

  //-------------------------touchEvent-----------------------------------//

  // useEffect(() => {
  //   const handleTouchMove = (e) => {
  //     if (!showIframe) {
  //       e.preventDefault();
  //       const { clientX, clientY } = e.changedTouches[0];
  //       const container = document.getElementById("container");
  //       const containerReact = container.getBoundingClientRect();
  //       const dragItem = e.target;

  //       const dragItemWidth = dragItem.offsetWidth;
  //       const dragItemHeight = dragItem.offsetHeight;
  //       const boundaryOffset = 5;

  //       const maxLeft = containerReact.left + boundaryOffset;
  //       const maxTop = containerReact.top + boundaryOffset;
  //       const maxRight = containerReact.right - dragItemWidth - boundaryOffset;
  //       const maxBottom =
  //         containerReact.bottom - dragItemHeight - boundaryOffset;

  //       let left = clientX - 50;
  //       let top = clientY - 10;

  //       if (left < maxLeft) {
  //         left = maxLeft;
  //       } else if (left > maxRight) {
  //         left = maxRight;
  //       }

  //       if (top < maxTop) {
  //         top = maxTop;
  //       } else if (top > maxBottom) {
  //         top = maxBottom;
  //       }

  //       dragItem.style.transform = `translate(${left}px, ${top}px)`;
  //     }
  //   };

  //   const topElement = document.getElementById("top");
  //   topElement.addEventListener("touchmove", handleTouchMove);

  //   return () => {
  //     topElement.removeEventListener("touchmove", handleTouchMove);
  //   };
  // }, [showIframe]);

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.origin === REACT_APP_CURRENT_URL) return;

      if (e.data === "readyToTakeScreenShot") {
        setOpen(false);
      }
      if (e.data === "failureToTakeImageOperation") {
        setOpen(true);
      }
      if (e.data === "readyImage") {
        setOpen(true);
      }
      if (e.data.image) {
        setImageUrl(e.data.image);
      }
      setFormData((pre) => {
        return { ...pre, screenshot: e.data.image };
      });
    });
  }, []);

  useEffect(() => {
    if (imageUrl) {
      setTakeShot(false);
    }
  }, [imageUrl]);

  useEffect(() => {
    if (isValidate) {
      const validationError = validation(formData);
      setErrors(validationError);
    }
  }, [formData, isValidate]);

  // useEffect(() => {
  //   if (open) {
  //     axios
  //       .get(`${REACT_APP_NODE_BACKEND_URL}/support/getLastTicketNo`)
  //       .then((res) => {
  //         setTicketNo(res.data.message);
  //       })
  //       .catch((e) => console.log(e.message));
  //   }
  // }, [open]);

  useEffect(() => {
    if (deviceType) {
      setAnchorEl(helpBtn.current);
      setOpen((prev) => placement !== "top-end" || !prev);
      setPlacement("top-end");
      setShowIframe(!showIframe);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errors.fileTypeAndSize) {
      const timer = setTimeout(() => {
        setErrors({});
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errors?.fileTypeAndSize]);

  return (
    <div className="main_container" id="container">
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        sx={{ backgroundColor: "transparent" }}
        transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ background: "green", width: "0", zIndex: 10000 }}>
              {submitted ? (
                <div
                  className="ps-3 helpInner m-1"
                  style={{
                    borderRadius: "10px",
                    width: "45%",
                  }}>
                  <div className="master_success_div">
                    <div className="success-icon f-15">
                      <CheckCircleOutlineIcon
                        style={{ fontSize: "100px", color: "white" }}
                      />
                      <p
                        style={{
                          fontSize: "25px",
                          color: "white",
                          fontWeight: "100",
                        }}>
                        SUCCESS
                      </p>
                    </div>
                    <div className="success_message_content">
                      <div className="mt-5">
                        <p>
                          Issue submitted successfully. Will check <br></br> and
                          update soon.
                        </p>
                      </div>
                      <div>
                        <button
                          style={{
                            background: "#458fe1",
                            color: "white",
                            padding: "10px 30px",
                            borderRadius: "25px",
                            border: "none",
                            textTransform: "capitalize",
                            cursor: "none",
                          }}>
                          Thank you
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="ps-3 rounded-3 helpInner m-1">
                  <div className="helpInnerView ">
                    <div
                      className="d-flex justify-content-between align-items-center mb-3 mt-3 py-2"
                      style={{
                        backgroundColor: "#448ee0",
                        position: "sticky",
                        top: "0",
                        zIndex: 999,
                      }}>
                      <div className="text_help mb-0">Help Desk</div>
                      <div
                        className="d-flex justify-content-center align-items-center close-btn mx-3 fwBold text-secondary"
                        onClick={() => {
                          setOpen(!open);
                          setShowIframe(!showIframe);
                          handleChangeIframeSize();
                        }}>
                        <ClearOutlinedIcon />
                      </div>
                    </div>
                    <div className="helpInnerView_card">
                      <div className="mb-2">
                        <h5>Contact us</h5>
                      </div>
                      <div className="mb-3">
                        <TextField
                          label="Your Name*"
                          variant="outlined"
                          size="small"
                          name="firstName"
                          error={errors.name && isValidate}
                          helperText={errors.name}
                          fullWidth
                          autoComplete="none"
                          value={formData.name}
                          onChange={(e) => {
                            handleChange(e.target.value, "name");
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <TextField
                          label="Email*"
                          variant="outlined"
                          size="small"
                          name="email"
                          error={errors.email && isValidate}
                          helperText={errors.email}
                          fullWidth
                          value={formData.email}
                          onChange={(e) =>
                            handleChange(e.target.value, "email")
                          }
                          autoComplete="none"
                        />
                      </div>
                      <div className="mb-3 phone_number">
                        <TextField
                          label="Mobile Number"
                          variant="outlined"
                          size="small"
                          name="mobileNumber"
                          type="number"
                          fullWidth
                          value={formData.mobileNumber}
                          onChange={(e) =>
                            handleChange(e.target.value, "mobileNumber")
                          }
                          autoComplete="none"
                        />
                      </div>
                      <div className="mb-3">
                        <TextField
                          label="Subject*"
                          variant="outlined"
                          size="small"
                          name="subject"
                          error={errors.subject && isValidate}
                          helperText={errors.subject}
                          fullWidth
                          value={formData.subject}
                          onChange={(e) =>
                            handleChange(e.target.value, "subject")
                          }
                          autoComplete="none"
                        />
                      </div>
                      <div className="mb-3">
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.type}
                            label="Type"
                            onChange={(e) =>
                              handleChange(e.target.value, "type")
                            }>
                            <MenuItem value={"Issue"}>Issue</MenuItem>
                            <MenuItem value={"Feature"}>Feature</MenuItem>
                            <MenuItem value={"Data Update"}>
                              Data Update
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="mb-3 d-none">
                        <TextField
                          label="Client Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={formData.clientName}
                          disabled={true}
                          autoComplete="none"
                        />
                      </div>
                      <div className="mb-3">
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Priority
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.priority}
                            label="Priority"
                            onChange={(e) =>
                              handleChange(e.target.value, "priority")
                            }>
                            <MenuItem value={"Low"}>Low</MenuItem>
                            <MenuItem value={"Medium"}>Medium</MenuItem>
                            <MenuItem value={"High"}>High</MenuItem>
                            <MenuItem value={"Urgent"}>Urgent</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <input
                        id="multiple-file-input"
                        style={{ display: "none" }}
                        type="file"
                        multiple
                        autoComplete="none"
                        onChange={(e) =>
                          handleChange(e.target.files, "attachment")
                        }
                      />
                      <div
                        onClick={() =>
                          document.getElementById("multiple-file-input").click()
                        }
                        onDragOver={(event) => event.preventDefault()}
                        onDrop={(e) => {
                          e.preventDefault();
                          handleChange(e.dataTransfer.files, "attachment");
                        }}
                        className="mt-3 upload_file">
                        <BackupIcon />

                        <h6>upload files (max 5)</h6>
                        <div style={{ fontSize: "13px" }} className="mb-2">
                          Accepted file format (png, jpg, jpeg ,xlsx)
                        </div>
                        <p>Click to add or drag & drop files.</p>
                      </div>
                      <span
                        className="text-danger"
                        style={{ fontSize: "12px" }}>
                        {errors.fileTypeAndSize}
                      </span>
                      {formData.attachment.length > 0 && (
                        <div className="mb-3 mt-3">
                          <h4 className="upload_file_count">
                            {`${formData.attachment.length} Attachment`}
                          </h4>
                          <div className="upload_file_container">
                            {/* <div className="upload_file_content"> */}
                            {formData.attachment.map((data, index) => {
                              return (
                                <div className="upload_file_content">
                                  <div>{getShortString(data.name, 20)}</div>
                                  <i
                                    class="fa fa-trash"
                                    aria-hidden="true"
                                    onClick={() => handleFileRemove(index)}></i>
                                </div>
                              );
                            })}
                            {/* </div> */}
                          </div>
                        </div>
                      )}
                      {deviceType ? (
                        ""
                      ) : (
                        <div className="my-3 d-grid">
                          {takeShot ? (
                            <button
                              className="take_screen_shot"
                              onClick={handleTakeScreenShot}>
                              <LuImagePlus className="mx-1" />
                              Take screenShot
                            </button>
                          ) : (
                            <div className="screen_shot_image">
                              <img className="image" alt="img" src={imageUrl} />
                              <div className="cancel_button_outside">
                                <div
                                  className="cancel_button"
                                  onClick={() => {
                                    setTakeShot(!takeShot);
                                    setImageUrl(null);
                                  }}>
                                  <ClearIcon className="icon_color" size={5} />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      <div className={deviceType ? "my-3" : "mb-3"}>
                        <TextField
                          label="Description *"
                          fullWidth
                          multiline
                          name="description"
                          error={errors.description && isValidate}
                          helperText={errors.description}
                          maxRows={"10"}
                          minRows={"3"}
                          className="custom-multi-select"
                          value={formData.description}
                          onChange={(e) =>
                            handleChange(e.target.value, "description")
                          }
                        />
                      </div>
                      <div className="d-flex justify-content-end mt-3 mb-1">
                        <Button
                          variant="contained"
                          // aria-describedby={id}
                          color="primary"
                          onClick={() => {
                            handleSubmit();
                          }}
                          fullWidth>
                          send
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Paper>
          </Fade>
        )}
      </Popper>

      <Button
        // onMouseDown={initialiseDrag}
        // ref={dragItem}
        // id="top"
        ref={helpBtn}
        variant="contained"
        color="primary"
        className="help_btn d-flex text-capitalize "
        style={{
          borderRadius: "18px 3px 18px 18px",
          position: "fixed",
          bottom: "5px",
          left: "5px",
          boxShadow: "none",
        }}
        startIcon={<RiQuestionLine />}
        onClick={handleClick("top-end")}>
        {showIframe ? "Close" : "Help"}
      </Button>
    </div>
  );
}
export default Help;
