import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Help from "./Help";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/help" element={<Help />} />  
        
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
